import CustomerStoryHorizontal from "@components/BasicComponents/Cards/CustomerStory/CustomerStoryHorizontal"
import SwiperT from "@components/BasicComponents/Swiper/MySwiper"
import ContainerWrapper from "@components/BasicComponents/Wrappers/ContainerWrapper/ContainerWrapper"
import NewResponsiveImage from "@components/CustomTypography/NewResponsiveImage/NewResponsiveImage"
import Separator from "@components/CustomTypography/Separator/Separator"
import Title from "@components/CustomTypography/Title/Title"
import ButtonComponent from "@components/Theme/Button/Button"
import {
	AdvancedSideAssetModuleType,
	CustomerStoryModuleType,
	LogosModuleType,
} from "@hooks/WorkWithJson/GetComponentDataByKey/GetComponentDataByKeyTypes"
import React from "react"
import Marquee from "react-fast-marquee"
import {SwiperSlide} from "swiper/react"

import styles from "./HomeCustomersSection.module.scss"

type Props = {
	data: AdvancedSideAssetModuleType
	stories: CustomerStoryModuleType[]
	logos: LogosModuleType[]
}

const HomeCustomersSection = (props: Props) => {
	return (
		<Separator size={128}>
			<ContainerWrapper className={styles.container}>
				<Title
					content={props.data.sectionTitle}
					fontWeight={600}
					size={"XL"}
					center
				/>
				<Separator />
				<SwiperT
					className={"SwiperCustomer"}
					module={props.stories.map(
						(element: CustomerStoryModuleType, index: number) => {
							if (index < 3) {
								return (
									<SwiperSlide key={index}>
										<CustomerStoryHorizontal
											className={styles.cardContainer}
											data={element}
										/>
									</SwiperSlide>
								)
							}
							return undefined
						},
					)}
					spaceBetween={200}
				/>
				<Separator />

				<Marquee className={styles.logosBox} speed={50} pauseOnHover>
					{props.logos.map(
						(element: LogosModuleType, index: number) => {
							return (
								<NewResponsiveImage
									key={index}
									imageClassName={styles.image}
									withoutContainer
									{...element.logo}
								/>
							)
						},
					)}
				</Marquee>
				<Separator size={32} />
				<div className={styles.bottomButton}>
					<ButtonComponent
						buttonType={"secondary"}
						link={props.data.sectionBottomButtonLink}
						asLink
					>
						{props.data.sectionBottomButton}
					</ButtonComponent>
				</div>
			</ContainerWrapper>
		</Separator>
	)
}

export default HomeCustomersSection

import ImageTextCard from "@components/BasicComponents/Cards/ImageTextCard/ImageTextCard"
import ContainerWrapper from "@components/BasicComponents/Wrappers/ContainerWrapper/ContainerWrapper"
import Separator from "@components/CustomTypography/Separator/Separator"
import Title from "@components/CustomTypography/Title/Title"
import {
	AdvancedSideAssetMainContent,
	AdvancedSideAssetModuleType,
} from "@hooks/WorkWithJson/GetComponentDataByKey/GetComponentDataByKeyTypes"
import React from "react"

import styles from "./HomeSecuritySection.module.scss"

type Props = {
	data: AdvancedSideAssetModuleType
}

const HomeSecuritySection = (props: Props) => {
	return (
		<section style={{backgroundColor: "#f5f5f5"}}>
			<ContainerWrapper>
				<Separator size={128}>
					<Title
						content={props.data.sectionTitle}
						fontWeight={600}
						size={"XL"}
						center
					/>
					<Separator />

					<div className={styles.cardContainer}>
						{props.data.mainContent?.map(
							(
								element: AdvancedSideAssetMainContent,
								index: number,
							) => {
								return (
									<ImageTextCard
										key={index}
										className={styles.cardBox}
										data={element}
										imageHeight={150}
										imageWidth={150}
										size={"S"}
									/>
								)
							},
						)}
					</div>
				</Separator>
			</ContainerWrapper>
		</section>
	)
}

export default HomeSecuritySection
